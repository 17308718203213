@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#app,
#app>div {
  height: 100%
}